
/**
 * @name: 礼品卡管理-礼品卡类别管理
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 礼品卡管理-礼品卡类别管理
 * @update: 2023-12-07 16:39
 */
import { Vue, Component, Watch } from "vue-property-decorator"
import {
  cardTypeListApi,
  cardTypeCreateApi,
  cardTypeModifyApi,
  getSortProductListApi,
  getSelectListApi,
  updateProductApi,
  categoryDetailApi
} from "@/apis/card/type"
import { ICardType, ICardTypeParam, ISortProduct } from "@/apis/card/type/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy } from "@/utils/common";

@Component({})
export default class cardType extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ICardType[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ICardTypeParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    labelWidth: '120px',
    dialogWidth: '600px',
    delBtn: false,
    column: [
      {
        "label": "礼品卡类别名称",
        "prop": "giftCategoryName",
        "align": "center",
        "search": true,
        "overHidden": true,
        "maxlength": 10,
        "rules": [
          {required: true, message: "礼品卡类别名称不能为空", trigger: "blur"},
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (!/^[A-Za-z0-9\u4e00-\u9fa5]+$/.test(value)) {
                return callback(new Error('只支持中文、字母、数字'))
              }
              callback()
            },
            trigger: "blur"
          }
        ]
      },
      {
        "label": "排序",
        "prop": "giftCategorySort",
        "align": "center",
        "type": "number",
        "rules": [
          {required: true, message: "排序不能为空", trigger: "blur"},
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (!value || value == '0') {
                return callback(new Error('排序不能为空和0'))
              }
              if (!/^[0-9]*[1-9][0-9]*$/.test(value)) {
                return callback(new Error('排序必须为正整数'))
              }
              if (parseInt(value) > 99) {
                return callback(new Error('排序最大值99'))
              }
              callback()
            },
            trigger: "blur"
          }
        ]
      },
      {
        "label": "适用商品范围",
        "prop": "productQty",
        "align": "center",
        "slot": true,
        "addHide": true,
        "editHide": true
      },
      {
        "label": "状态",
        "prop": "giftCategoryStatus",
        "align": "center",
        "type": "select",
        "search": true,
        "dicData": [
          {
            label: "启用",
            value: 1
          },
          {
            label: "禁用",
            value: 2
          }
        ],
        slot: true,
        "addHide": true,
        "editHide": true
      },
      {
        "label": "说明",
        type: "textarea",
        "prop": "giftCategoryIllustrate",
        "align": "center",
        "overHidden": true,
        maxlength: 100,
        showWordLimit: true,
        addSlot: true,
        editSlot: true
      },
      {
        "label": "创建时间",
        "prop": "addTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "overHidden": false,
        "search": true,
        "addHide": true,
        "editHide": true
      }
    ]
  }

  // 适用范围弹窗
  productScopeDialog = false
  // 商品、分类数据
  productSortList: ISortProduct[] = []
  // 选择的商品id
  selectProductIds: string[] = []
  // 是否全选
  checkedAll = false
  // 当前选择的分类id
  selectGiftTypeId = ''

  @Watch('selectProductIds')
  onSelectProductChange(newVal: string[]) {
    let allProductLength = 0
    for (let i = 0; i < this.productSortList.length; i++) {
      allProductLength += (this.productSortList[i]['productVoList'] ? this.productSortList[i]['productVoList'].length : 0)
    }
    if (allProductLength == newVal.length) {
      this.checkedAll = true
    } else {
      this.checkedAll = false
    }
  }

  getList () {
    this.tableLoading = true
    const query: ICardTypeParam = deepCopy(this.queryParam)
    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime
    cardTypeListApi(query).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  switchStatus (giftCategoryId: string, giftCategoryStatus: number) {
    cardTypeModifyApi({giftCategoryId, giftCategoryStatus}).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
      }
    })
  }


  rowSave (form: ICardType, done: Function, loading: Function) {
    cardTypeCreateApi(form).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: ICardType, done: Function, loading: Function) {
    cardTypeModifyApi(form).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  async openProductScope (giftCategoryId: string) {
    this.selectGiftTypeId = giftCategoryId;
    await this.getProductSortList()
    getSelectListApi(giftCategoryId).then(e => {
      if (e) {
        this.selectProductIds = e.map(item => item.productId);
        this.productScopeDialog = true
        setTimeout(() => {
          // @ts-ignore
          this.$refs.productScopeTreeRef.setCheckedKeys(this.selectProductIds, true)
        }, 0)
      }
    })
  }

  selectAll (status: boolean) {
    let ids = []
    if (status) {
      const arr = this.productSortList;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].productVoList && arr[i].productVoList.length) {
          for (let j = 0; j < arr[i].productVoList.length; j++) {
            let item = arr[i].productVoList[j]
            ids.push(item.id)
          }
        }
      }
    } else {
      ids = []
    }
    // @ts-ignore
    this.$refs.productScopeTreeRef.setCheckedKeys(ids, true)
  }

  handleProductScopeEnter (done: Function, loading: Function) {
    updateProductApi(this.selectGiftTypeId, this.selectProductIds).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  handleProductScopeClose () {

  }

  dealProductList (arr: ISortProduct[]) {
    if (!arr || !arr.length) {
      return
    }
    for (let i = 0; i < arr.length; i++) {
      // @ts-ignore
      arr[i]['id'] = arr[i]['productId'] || arr[i]['productSortId']
      // @ts-ignore
      arr[i]['name'] = arr[i]['productName'] || arr[i]['sortName']
      if (arr[i]['productVoList'] && arr[i]['productVoList'].length) {
        // @ts-ignore
        this.dealProductList(arr[i]['productVoList'])
      }
    }
  }

  getProductSortList () {
    return new Promise(resolve => {
      getSortProductListApi().then(e => {
        this.dealProductList(e)
        this.productSortList = e.filter(item => item.productVoList && item.productVoList.length)
        resolve(null)
      })
    })
  }

  productScopeTreeChange (data: any) {
    // @ts-ignore
    this.selectProductIds = this.$refs.productScopeTreeRef.getCheckedKeys(true)
  }

  openEdit (row: ICardType, index: number) {
    categoryDetailApi(row.giftCategoryId).then(e => {
      if (e) {
        // @ts-ignore
        this.$refs.crudRef.rowEdit(e, index)
      }
    })
  }

  created () {
    this.getList()
  }
}
