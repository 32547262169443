/**
 * @name: 礼品卡管理-礼品卡类别管理接口文件
 * @author:
 * @date: 2023-12-07 16:39
 * @description： 礼品卡管理-礼品卡类别管理接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { ICardTypeParam, ICardType, ISortProduct, ISelectShop } from "./types";

/**
 * 分页查询
 * @param param 查询参数
 * @returns
 */
export const cardTypeListApi = (param: ICardTypeParam) => get<IPageRes<ICardType[]>>("/card/gift/category/query", param)

/**
 * 创建
 * @param data 表单数据
 * @returns
 */
export const cardTypeCreateApi = (data: Partial<ICardType>) => postJ("/card/gift/category/create", data)

/**
 * 修改
 * @param data 表单数据
 * @returns
 */
export const cardTypeModifyApi = (data: Partial<ICardType>) => postJ("/card/gift/category/modify", data)
/**
 * 获取全部分类下的分类商品数据
 * @returns
 */
export const getSortProductListApi = () => get<ISortProduct[]>("/card/gift/category/product/getSortProductList")

/**
 * 获取选择的适用范围的商品
 * @param giftCategoryId 礼品卡类别
 * @returns
 */
export const getSelectListApi = (giftCategoryId: string) => get<ISelectShop[]>("/card/gift/category/product/getSelectList", {giftCategoryId})

/**
 * 更新商品适用范围
 * @param giftCategoryId 礼品卡类别id
 * @param productIds 商品id集合
 * @returns
 */
export const updateProductApi = (giftCategoryId: string, productIds: string[]) => postJ("/card/gift/category/update/product", {giftCategoryId, productIds})
/**
 * 详情
 * @param id
 */
export const categoryDetailApi = (id: string) => get<ICardType>("/card/gift/category/detail/" + id)
